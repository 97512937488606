<template>
    <b-card>
        <b-card-header>
            <b-card-title>Ürünler</b-card-title>
        </b-card-header>
        <b-card-body>



            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Ara</label>
                        <b-form-input
                            v-model="searchTerm"
                            placeholder="Ara..."
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>


            <vue-good-table
                :columns="columns"
                :rows="items"
                :rtl="false"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength}">
                <template
                    slot="table-row"
                    slot-scope="props">
                    <div
                        v-if="props.column.field === 'name'"
                        class="text-nowrap">
                        <span class="text-nowrap">{{ props.row.name }}</span>
                    </div>
                    <div
                        v-else-if="props.column.field === 'category.name'"
                        class="text-nowrap">
                        <span class="text-nowrap">{{ props.row.category.name }}</span>
                    </div>
                    <div
                        v-else-if="props.column.field === 'id'"
                        class="text-nowrap">
                        <div class="text-center">{{ props.row.id }}</div>
                    </div>
                    <div
                        v-else-if="props.column.field === 'brand.name'"
                        class="text-nowrap">
                        <span class="text-nowrap">{{ props.row.brand.name }}</span>
                    </div>

                      <b-button-group
                          v-else-if="props.column.field === 'action'"
                          size="sm"
                      >
                        <b-button
                            variant="outline-primary"
                            :to="{ name: 'edit-product', params:{product:props.row.id} }">
                          <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="deleteItem(props.row.id)">
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>

                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>



                <div slot="emptystate" class="d-flex w-100 justify-content-center py-3">
                    {{loading?'Ürünler yükleniyor. Lütfen bekleyin.':'Ürün bulunamadı.'}}
                </div>





                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                              Sayfa başı
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['10','20','50']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap ">ürün göster</span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>

        </b-card-body>
    </b-card>
</template>

<script>

import {getUserData} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BButtonGroup,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BAvatar, BBadge
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
    name: 'list',
    data(){
        return {
            userData: getUserData(),
            items: [],
            searchTerm: '',
            pageLength: 20,
            loading: false,
            columns: [
                {
                    label: '#',
                    field: 'id',
                    filterOptions: {
                        enabled: true,
                        placeholder: '#',
                    },
                },
                {
                    label: 'Kategori',
                    field: 'category.name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Kategorilerde ara',
                    },
                },
                {
                    label: 'Marka',
                    field: 'brand.name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Markalarda ara',
                    },
                },
                {
                    label: 'Ürün Adı',
                    field: 'name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Ürün adında ara',
                    },
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                },
            ]
        }
    },
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BButtonGroup,
        BButton,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    created(){
        let t = this
        t.loading = true
        this.$http.get('product/listProducts/'+t.userData.companyId).then(result => {
            const data = result.data
            if(data.status){
                t.items = data.data
            }else{
                if(data.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: data.message,
                        },
                    })
                }
            }
        }).catch(err=>{console.log(err)}).then(f=>{
            t.loading = false
        })
    },
    methods:{
        deleteItem(id){
            let t = this
            let fIndex = t.findItemIndex(id)

            this.$swal({
                title: 'Emin misiniz?',
                text: "Ürünü silmek istediğinize emin misiniz? Bu işlem geri alınamaz!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-outline-info ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return t.$http.delete('product/removeProduct/' + id).then(result => {
                        return result.data
                    }).catch(err=>{console.log(err)})
                }
            }).then(result => {
                let res = result.value
                if (res) {
                    if (res.status) {
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                    text: res.message
                                },
                            })
                        }
                        t.items.splice(fIndex, 1)
                    } else {
                        //Hata mesajı w/result.value.message
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Hata!',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                    text: res.message
                                },
                            })
                        }
                    }
                }
            })

        },
        findItemIndex(id){
            return this.items.findIndex(f=>{return f.id === id})
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
